import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <p>
          Made with{" "}
          <span aria-hidden="true" role="img">
            ♥️
          </span>{" "}
          by Luis Romo
        </p>
      </div>
    );
  }
}
export default Footer;
